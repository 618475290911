<template>
    <div class="vendor">
        <div class="buyersInfo">
            <div class="userTitle">
                <span>{{ $t('xin-xi-wei-hu') }}</span>
                <p class="dividingLine"></p>
            </div>
            <el-form
                class="mainForm"
                :rules="rules"
                :model="form"
                ref="form"
                :label-width="!isChinese ? '280px' : '180px'"
            >
                <el-form-item :label="$t('qi-ye-logo')" prop="logo">
                    <div class="logoList">
                        <crop-upload
                            v-model="form.logo"
                            :width="500"
                            :height="500"
                            :ratio="[1, 1]"
                            :imgWidth="160"
                            name="logo"
                        >
                            <div slot="tips" class="tips">{{ $t('chi-cun') }}500x500px</div>
                        </crop-upload>
                        <crop-upload
                            v-model="form.logo1"
                            :width="280"
                            :height="120"
                            :ratio="[7, 3]"
                            :imgWidth="224"
                            key="22"
                            name="logo1"
                        >
                            <div slot="tips" class="tips">{{ $t('chi-cun') }}280x120px</div>
                        </crop-upload>
                    </div>
                </el-form-item>
                <el-form-item :label="$t('qi-ye-ming-cheng')" prop="chCompanyName" :rules="rulesRequire">
                    <el-input class="input2" v-model="form.chCompanyName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('shi-fou-zai-zhong-guo-she-li-gong-si-ban-shi-chu')" prop="branch">
                    <el-radio-group v-model="form.branch" :disabled="isVendor">
                        <el-radio :label="item.value" v-for="item in boolOptions" :key="item.value">{{
                            $t(item.label)
                        }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item :label="$t('qi-ye-ying-wen-ming')" prop="enCompanyName">
                    <el-input class="input2" v-model="form.enCompanyName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('wang-zhan-zhu-ye')" prop="homepage">
                    <el-input
                        class="input3"
                        :placeholder="$t('shu-ru-nin-de-gong-si-xian-shang-wang-zhan-di-zhi')"
                        v-model="form.homepage"
                    >
                        <template slot="prepend">Http://</template>
                    </el-input>
                </el-form-item>

                <el-form-item :label="$t('qi-ye-lei-bie')" prop="enterpriseType">
                    <el-checkbox-group v-model="form.enterpriseType">
                        <el-checkbox
                            :label="item.value"
                            :key="index"
                            v-for="(item, index) in enterpriseTypes"
                            name="enterpriseType"
                            >{{ item.label }}</el-checkbox
                        >
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item :label="$t('qi-ye-shu-xing')" prop="businessCategory">
                    <el-radio-group v-model="form.businessCategory">
                        <el-radio :label="item.value" v-for="item in businessCategorys" :key="item.value">{{
                            item.label
                        }}</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item :label="$t('qi-ye-gui-mo')" prop="enterpriseSize">
                    <el-select
                        class="input3"
                        v-model="form.enterpriseSize"
                        :placeholder="$t('qing-xuan-ze-qi-ye-gui-mo')"
                    >
                        <el-option
                            v-for="item in enterpriseSizes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('suo-shu-hang-ye')" prop="industryId">
                    <div class="input3">
                        <Industry :ids.sync="form.industryId" :label.sync="form.customIndustry" />
                    </div>
                </el-form-item>
                <el-form-item :label="$t('zhu-yao-chan-pin-fu-wu')" prop="productCategoryId">
                    <div class="input3">
                        <ProductCategory
                            v-model="form.productCategoryId"
                            :label.sync="form.customCategory"
                            :limit="6"
                            :backString="false"
                        />
                    </div>

                    <div class="input3" v-if="form.customCategory">
                        <create
                            v-model="form.enCustomCategory"
                            :placeholder="$t('zi-ding-yi-chan-pin-fu-wu-ying-wen')"
                        />
                    </div>
                </el-form-item>

                <el-form-item :label="$t('ying-yong-ling-yu')" prop="applicationField">
                    <div class="input3">
                        <ApplicationField
                            class="input3"
                            :ids.sync="form.applicationField"
                            :label.sync="form.customApplicationField"
                            :backString="false"
                        />
                    </div>

                    <div class="input3" v-if="form.customApplicationField">
                        <create
                            v-model="form.enCustomApplicationField"
                            :placeholder="$t('zi-ding-yi-chan-pin-fu-wu-ying-wen')"
                        />
                    </div>
                </el-form-item>

                <el-form-item :label="$t('gan-xing-qu-de-chan-pin')" prop="interestedProCateId">
                    <div class="input3">
                        <ProductCategory
                            v-model="form.interestedProCateId"
                            :label.sync="form.customCategoryId"
                            :backString="false"
                        />
                    </div>
                </el-form-item>

                <el-form-item :label="$t('qi-ye-jian-jie')" prop="introduction">
                    <div class="input3">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 6, maxRows: 8 }"
                            :maxlength="1000"
                            show-word-limit
                            :placeholder="$t('qing-shu-ru-qi-ye-jie-shao')"
                            v-model="form.introduction"
                            resize="none"
                        >
                        </el-input>
                    </div>

                    <div class="input3" style="margin-top: 20px" v-if="form.introduction">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 6, maxRows: 8 }"
                            show-word-limit
                            :placeholder="$t('ying-wen-jian-jie')"
                            v-model="form.enIntroduction"
                            resize="none"
                        >
                        </el-input>
                    </div>
                </el-form-item>

                <el-form-item v-if="form.branch" :label="$t('tong-yi-she-hui-xin-yong-dai-ma')" prop="uscc">
                    <el-input
                        class="input2"
                        v-model="form.uscc"
                        :maxlength="18"
                        :placeholder="$t('qing-shu-ru-18-wei-tong-yi-she-hui-xin-yong-dai-ma')"
                    ></el-input>
                </el-form-item>

                <el-form-item v-if="form.branch" :label="$t('ying-ye-zhi-zhao-qi-xian')" prop="businessLicensePeriod">
                    <div class="form-right">
                        <el-date-picker
                            class="input2"
                            v-model="form.businessLicensePeriod"
                            type="date"
                            :placeholder="$t('qing-xuan-ze-dao-qi-shi-jian')"
                            :disabled="form.noLimit"
                        >
                        </el-date-picker>
                        <el-checkbox v-model="form.noLimit">{{ $t('wu-xian-qi') }}</el-checkbox>
                    </div>
                </el-form-item>
                <el-form-item v-if="form.branch" :label="$t('ying-ye-zhi-zhao')" prop="businessLicenseImg">
                    <!-- <crop-upload
                        v-model="form.businessLicenseImg"
                        :ratio="[1, 1]"
                        :width="300"
                        :height="300"
                        :imgWidth="160"
                        name="businessLicenseImg"
                        :size="2048"
                    >
                        <div slot="tips">
                            请上传清晰图片，格式jpg/png，文件大小不超过2M
                        </div>
                    </crop-upload> -->

                    <file-upload
                        v-model="form.businessLicenseImg"
                        single
                        format="string"
                        accept=".pdf,.jpg,.png"
                        :size="2"
                    >
                        <div slot="tips">
                            {{ $t('ge-shi-yao-qiu-jpgpngpdf-dan-wen-jian-da-xiao-bu-chao-guo-2m') }}
                        </div>
                    </file-upload>
                </el-form-item>
            </el-form>

            <el-divider></el-divider>
            <div class="userTitle">
                <span>{{ $t('lian-xi-fang-shi') }}</span>
                <p class="dividingLine"></p>
            </div>

            <div class="info-list">
                <!-- <div class="tag">总部</div> -->
                <div class="info-content">
                    <div class="info-item address">
                        <div class="name">{{ $t('di-zhi') }}</div>
                        <div class="val">
                            <Area
                                :country.sync="form.country"
                                :province.sync="form.province"
                                :city.sync="form.city"
                                :continent.sync="form.continent"
                            />
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 4 }"
                                style="margin-top: 14px"
                                :placeholder="$t('qing-shu-ru-xiang-xi-di-zhi')"
                                v-model="form.address"
                                resize="none"
                            ></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('you-bian') }}</div>
                        <div class="val">
                            <el-input v-model="form.postalCode" :placeholder="$t('qing-shu-ru-you-bian')"></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('cheng-hu') }}</div>
                        <div class="val">
                            <el-select v-model="form.userSex" :placeholder="$t('qing-xuan-ze')">
                                <el-option
                                    v-for="item in sexModels"
                                    :key="item[0]"
                                    :label="item[1].label"
                                    :value="item[0]"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="name">{{ $t('lian-xi-ren') }}</div>
                        <div class="val">
                            <el-input v-model="form.nickname" :placeholder="$t('qing-shu-ru-lian-xi-ren')"></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('zhi-wei') }}</div>
                        <div class="val">
                            <el-input v-model="form.userPosition" :placeholder="$t('qing-shu-ru-zhi-wei')"></el-input>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="name">{{ $t('you-xiang') }}</div>
                        <div class="val">
                            <el-input
                                v-model="form.userEmail"
                                disabled
                                :placeholder="$t('qing-shu-ru-you-xiang')"
                            ></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('dian-hua') }}</div>
                        <div class="val">
                            <el-input
                                v-model="form.userPhone"
                                disabled
                                :placeholder="$t('qing-shu-ru-dian-hua')"
                            ></el-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-list" v-for="(item, index) in branchInformation" :key="index">
                <div class="tag">{{ $t('quan-qiu-zong-bu') }}</div>
                <!-- <el-button
                    type="danger"
                    @click="del(item, index)"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    circle
                ></el-button> -->
                <div class="info-content">
                    <div class="info-item address">
                        <div class="name">{{ $t('di-zhi') }}</div>
                        <div class="val">
                            <Area
                                :country.sync="item.country"
                                :province.sync="item.province"
                                :city.sync="item.city"
                                :continent.sync="item.continent"
                            />
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 4 }"
                                style="margin-top: 14px"
                                :placeholder="$t('qing-shu-ru-xiang-xi-di-zhi')"
                                v-model="item.address"
                                resize="none"
                            ></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('you-bian') }}</div>
                        <div class="val">
                            <el-input v-model="item.postalCode" :placeholder="$t('qing-shu-ru-you-bian')"></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('cheng-hu') }}</div>
                        <div class="val">
                            <el-select v-model="item.sex" :placeholder="$t('qing-xuan-ze')">
                                <el-option
                                    v-for="item in sexModels"
                                    :key="item[0]"
                                    :label="item[1].label"
                                    :value="item[0]"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="name">{{ $t('lian-xi-ren') }}</div>
                        <div class="val">
                            <el-input v-model="item.nickname" :placeholder="$t('qing-shu-ru-lian-xi-ren')"></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('zhi-wei') }}</div>
                        <div class="val">
                            <el-input v-model="item.position" :placeholder="$t('qing-shu-ru-zhi-wei')"></el-input>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="name">{{ $t('you-xiang') }}</div>
                        <div class="val">
                            <el-input v-model="item.email" :placeholder="$t('qing-shu-ru-you-xiang')"></el-input>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="name">{{ $t('dian-hua') }}</div>
                        <div class="val">
                            <el-input v-model="item.phone" :placeholder="$t('qing-shu-ru-lian-xi-dian-hua')"></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <el-button type="warning" plain size="small" @click="addNew" v-if="branchInformation.length === 0">{{
                    $t('bu-chong-quan-qiu-zong-bu-di-zhi')
                }}</el-button>

                <div class="submit">
                    <el-button type="warning" size="small" @click="onSubmit">{{ $t('bao-cun') }}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import formPage from '../../mixins/formPage';
import ProductCategory from '../../components/select/ProductCategory.vue';
import ApplicationField from '../../components/select/ApplicationField';
import Industry from '../../components/select/Industry.vue';
import Area from '../../components/AreaInput';
import { format, parse } from 'date-fns';
import Create from '../../components/select/Create.vue';
import { boolModels, EnterpriseType, businessCategory, enterpriseSize } from '../../utils/AppState';
export default {
    name: 'vendor',
    mixins: [formPage],
    data() {
        return {
            form: {
                chCompanyName: '',
                enterpriseType: [],
                branch: true,
                productCategoryId: [],
                applicationField: [],
                addressB: {},
                address: '',
                noLimit: false
            },
            noEnd: false,
            rules: {},
            branchInformation: []
        };
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo', 'buyersInfo']),
        boolOptions() {
            return [...boolModels].map(item => {
                return {
                    label: item[1],
                    value: item[0]
                };
            });
        },
        enterpriseTypes() {
            return [...EnterpriseType].map(item => {
                return {
                    label: this.$i18n.t(item[1]),
                    value: item[0]
                };
            });
        },
        businessCategorys() {
            return [...businessCategory].map(item => {
                return {
                    label: this.$i18n.t(item[1]),
                    value: item[0]
                };
            });
        },
        enterpriseSizes() {
            return [...enterpriseSize].map(item => {
                return {
                    label: this.$t(item[1].name),
                    value: item[0]
                };
            });
        },
        branchInformationJSON() {
            const list = [...this.branchInformation].filter((item, index) => {
                return index === 0;
            });
            return JSON.stringify(list);
        }
    },
    components: {
        ProductCategory,
        ApplicationField,
        Area,
        Industry,
        Create
    },
    mounted() {
        this.$store.dispatch('getBuyInfo').then(res => {
            if (res.businessLicensePeriod) {
                res.businessLicensePeriod = parse(res.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss', new Date());
            }
            res.enterpriseType = res.enterpriseType || [];
            if (res.branchInformation) {
                const info = JSON.parse(res.branchInformation);
                if (info instanceof Array) {
                    this.branchInformation = info.slice(0, 1);
                }
            }
            this.form = { ...res };
        });
        // this.$http
        //     .post('/buyersInfo/getInfo', {
        //         userId: this.$store.state.userInfo.id
        //     })
        //     .then(res => {
        //         if (res.businessLicensePeriod) {
        //             res.businessLicensePeriod = parse(res.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss', new Date());
        //         }
        //         res.enterpriseType = res.enterpriseType || [];
        //         if (res.branchInformation) {
        //             const info = JSON.parse(res.branchInformation);
        //             if (info instanceof Array) {
        //                 this.branchInformation = info.slice(0, 1);
        //             }
        //         }
        //         this.form = { ...res };
        //     });
    },
    methods: {
        addNew() {
            this.branchInformation.push({
                nickname: '',
                phone: '',
                sex: '',
                email: '',
                position: '',
                city: '',
                province: '',
                country: '',
                area: '',
                address: '',
                postalCode: ''
            });
        },
        del(info, index) {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'warning'
            }).then(() => {
                const branchInformation = [...this.branchInformation];
                if (info.id) {
                    info.del = true;
                    branchInformation[index] = info;
                } else {
                    branchInformation.splice(index, 1);
                }

                this.branchInformation = branchInformation;
            });
        },
        submit() {
            console.log('save');
            const form = { ...this.form };
            form.branchInformation = this.branchInformationJSON;
            if (form.businessLicensePeriod) {
                form.businessLicensePeriod = format(form.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss');
            }
            if (form.noLimit) {
                form.businessLicensePeriod = '';
            }
            console.log(!form.vendorId && !!this.buyersInfo);
            if (!form.vendorId && this.buyersInfo) {
                form.vendorId = this.buyersInfo.vendorId;
            }
            // delete form.userPhone;
            // delete form.userEmail;

            console.log(JSON.stringify(form));
            this.$http
                .post('/buyersInfo/saveVO', form, { body: 'json' })
                .then(res => {
                    this.$message.success(this.$t('bao-cun-cheng-gong'));
                })
                .catch(e => {
                    this.$message.warning(e.errror);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.vendor {
    overflow: hidden;
}
.mainForm {
    padding: 40px 0;
}
.logoList {
    display: flex;
    align-items: flex-end;

    .tips {
        text-align: center;
    }
}
.logoList > div {
    &:first-child {
        margin-right: 16px;
    }
}

.form-right {
    .el-checkbox {
        padding: 0 20px;
    }
}

.userTitle {
    margin-bottom: 30px;
    border: none;
}
.dividingLine {
    height: 1px;
    background-color: #dcdfe6;
}

.el-divider {
    background-color: #f5f7fa;
    height: 10px;
    margin: 0;
}
.bottom {
    width: 517px;
    margin: auto;

    .el-button {
        min-width: 130px;
        height: 36px;
    }

    .submit {
        padding: 60px 0 82px;
    }
}
.info-list {
    width: 517px;
    background: #f5f7fa;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto 20px;

    .el-button {
        position: absolute;
        right: 12px;
        top: 8px;
    }

    .tag {
        padding: 0 6px;
        height: 22px;
        background: rgba(255, 165, 38, 0.3);
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: #0f264d;
        position: absolute;
        left: 0;
        top: 12px;
    }
}

.info-content {
    padding: 44px 30px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .info-item {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &.address {
            width: 100%;
            align-items: flex-start;
            .val {
                width: 400px;
            }
        }

        .name {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #565b66;
            line-height: 22px;
            min-width: 60px;
            margin-right: 10px;
            text-align: right;
        }

        .val {
            width: 160px;
        }
    }
}

.mainForm {
    max-width: 600px;
}

.isEn {
    .mainForm {
        max-width: 800px;
    }
}

.input3 + .input3 {
    margin-top: 10px;
}
</style>
